import React, { Fragment } from "react"
import { injectIntl } from "gatsby-plugin-react-intl"
import HeroSlider from "components/HeroSlider"
import {
    Spacing,
    SharedStyles,
    Container,
} from "../../../kenra-storybook/index"
import CustomLink from 'components/Link'
const { StPageTitle, StLinkMore } = SharedStyles
import styled, { css } from "styled-components"
import { ButtonPlay } from 'kenra-storybook'
import settings from "../../../kenra-storybook/global/settings"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { AutoScrollBeforeAfterImage, BeforeAfterRowImage } from "./AutoScrollImage"
import { ProductCarousel } from "./ProductCarousel"

export const CssButton = css`
    letter-spacing: 1px;
    display: inline-block !important;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.29;
    text-transform: uppercase;
    text-decoration: none !important;
    color: #fff;
    background-color: #121212;
    border: 1px solid #121212;
    padding: 13px 30px;
    text-align: center;
    transition: opacity 0.3s;
    border-radius: 0;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }

    &[type='submit'] {
        cursor: pointer;
    }
`

export const CssLinkMore = css`
    a {
        ${CssButton}
    }
`

export const ItemImageWrapper = styled.div.attrs(props => ({
    className: 'ItemImageWrapper',
}))`
    color: #121212;
    padding-left: 9px;
    padding-right: 9px;
    position: relative;
    min-height: 134px;
    @media (min-width: ${settings.bp.medium.view}) {
        padding-left: 0px;
        padding-right: 0px;
        max-width: 1200px;
        margin: auto;
    }

    object-fit: contain;
    & img:nth-child(1) {
        // display: block;
    }
    & img:nth-child(2) {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        left: 0;
        top: 0;
    }
    @media (min-width: ${settings.bp.medium.view}) {
        &:hover {
            & img:nth-child(1) {
                // display: none;
            }
            & img:nth-child(2) {
                display: block;
            }
        }
    }
`

const DesktopImage = styled.img`
    width: 100%;
    height: 100%;
    display: block;

    @media (max-width: ${settings.bp.medium.view}) {
        display: none;
    }
`

export const MobileImage = styled.img`
    width: 100%;
    height: 100%;
    display: none;
    @media (max-width: ${settings.bp.medium.view}) {
        display: block;
    }
`


const MobileShadow = styled.div`
    @media (max-width: ${settings.bp.medium.view}) {
        border-radius: 12px;
        background: #FFF;
        box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.08);
        padding: 12px 16px 24px 16px;
        margin-left: 21px;
        margin-right: 21px;
        
    }
`

const Image = styled.img`
    width: 100%;
    height: 100%;
    display: block;
`

const ImageOverlay = styled.div`
    color: #121212;
    position: absolute;
    left: 15px;
    bottom: 15px;
    width: calc(100% - 30px);
    height: 300px;
    align-content: end;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 115%;
    letter-spacing: 2px;
    padding-left: 48px;
    padding-right: 48px;
    @media (min-width: ${settings.bp.small.view}) {
        width: calc(50% - 30px);
        left: calc(50% + 15px);
        max-width: 600px;
    }
    pointer-events: none;
`

export const OverlayLink = styled.div.attrs(props => ({
    className: 'OverlayLink',
}))`
    ${CssLinkMore}

    a {
        pointer-events: auto;
        border-radius: 50px;
        font-size: 13px !important;
        @media (min-width: ${settings.bp.small.view}) {
            font-size: 20px !important;
        }
    }
    color: #121212;
    padding: 13px 65px;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 24px;

    @media (min-width: ${settings.bp.medium.view}) {
        margin-top: 24px;
        margin-bottom: 39px;
    }
    ${props =>
        props.hoverColor &&
        css`
            a {
                &:hover {
                    border: 1px solid ${props.hoverColor} !important;
                    color: #121212;
                    background-color: ${props.hoverColor};
                }
            }
        `};

`


const BeforeAfterRow = styled.div`
    margin: auto;
    display: flex;
    flex-direction: column;
    margin-left: 19px; 
    margin-right: 19px; 
    color: #121212;
    @media (min-width: ${settings.bp.medium.view}) {
        margin-left: 15%; 
        margin-right: 15%;
        display: flex;
        flex-direction: row;
    }
    ${props =>
        props.numberInRow === 4 &&
        css`
        flex-direction: row;
        flex-wrap: wrap;
        @media (min-width: ${settings.bp.medium.view}) {
            margin-left: 10%; 
            margin-right: 10%;
            flex-direction: row;
            flex-wrap: unset;
        }
        `};
`

const BeforeAfterTitleBlock = styled.div`
    font-weight: 500;
    font-size: 24px;
    line-height: 110%;
    letter-spacing: 2px;
    color: #121212;
    padding: 50px;
    display: flex;
    background-color: rgb(225, 214, 248);
    border-radius: 16px;
    margin: 1vw;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    justify-content: center;
    @media (min-width: ${settings.bp.medium.view}) {
        width: 44%;
    }
    ${props =>
        props.numberInRow === 4 &&
        css`
        padding: 70px;
        @media (min-width: ${settings.bp.medium.view}) {
            width: 25%;
            padding: 29px;
        }
        `};

`


const DesktopProductRow = styled.div`
    display: none;
    @media (min-width: ${settings.bp.medium.view}) {
        display: flex;
        margin-left: 15%; 
        margin-right: 15%;
        flex-direction: row;
    }
`

const DesktopAlternativeProductContainer = styled.div`
    display: none;
    @media (min-width: ${settings.bp.medium.view}) {
        display: flex;
        margin-left: 15%; 
        margin-right: 15%;
        flex-direction: row;
    }
`

const DesktopAlternativeProduct = styled.div`
    display: none;
    @media (min-width: ${settings.bp.medium.view}) {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 10px;
    }
`

const ProductImageContainer = styled.div`
  width: 100%;
  margin: 10px;
  @media (min-width: ${settings.bp.medium.view}) {
    width: 50%;
}
`

const ProductFullImageContainer = styled.div`
  width: 100%;
  margin: 10px;
  @media (min-width: ${settings.bp.medium.view}) {
    width: 100%;
}
`

const ProductInfoContainer = styled.div`
    margin: 10px;
    width: 100%;
    align-content: center;
    border-radius: 16px;
    background: #FFF;
    color: #121212;
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.08);
    @media (min-width: ${settings.bp.medium.view}) {
        width: 50%;
    }
`

export const ProductInfoTitle = styled.div`
    font-size: 24px;
    line-height: 115%;
    letter-spacing: 2px;
    font-weight: 500;
    text-align: center;
    padding-left: 100px;
    padding-right: 100px;
    color: #121212;
    margin: 5px;
    ${props =>
        props.wide &&
        css`
        padding-left: 6px;
        padding-right: 6px;
        `};
`
export const ProductInfoText = styled.div`
    font-size: 16px;
    line-height: 115%;
    letter-spacing: 2px;
    font-weight: 500;
    opacity: 75%;
    text-align: center;
    padding-left: 64px;
    color: #121212;
    padding-right: 64px;
    margin-bottom: 10px;
    ${props =>
        props.wide &&
        css`
        padding-left: 6px;
        padding-right: 6px;
        `};
`

const VideoImage = styled.div`
    margin-left: 20px;
    margin-right: 20px;
    @media (min-width: ${settings.bp.medium.view}) {
        max-width: 1200px;
        margin: auto;
    }
`


export const purple = '#E1D6F8'
const parseSectionData = sectionData => {
    return sectionData?.fragments?.map(section => {
        let returnData = {}
        section.forEach(fragmentData => {
            switch (fragmentData.key) {
                case "Image":
                    returnData["img"] = fragmentData.value
                    break
                case "Text":
                    returnData["text"] = fragmentData.value
                    break
                case "LinkText":
                    returnData["link"] = fragmentData.value
                    break
                case "LinkUrl":
                    returnData["url"] = fragmentData.value
                    break
                default:
                    break
            }
        })
        return {
            image: returnData.img,
            text: returnData.text,
            link: returnData.link?.toUpperCase(),
            url: returnData.url,
        }
    })
}

const parseSection1Data = section1Data => {
    return section1Data?.fragments?.map(section => {
        let returnData = {}
        section.forEach(fragmentData => {
            switch (fragmentData.key) {
                case "Image":
                    returnData["img"] = fragmentData.value
                    break
                case "Title":
                    returnData["text"] = fragmentData.value
                    break
                case "Text":
                    returnData["link"] = fragmentData.value
                    break
                case "Subtitle":
                    returnData["url"] = fragmentData.value
                    break
                default:
                    break
            }
        })
        return {
            image: returnData.img,
            text: returnData.text,
            link: returnData.link?.toUpperCase(),
            url: returnData.url,
        }
    })
}

const parseSection3Fragments = section3Data => {
    let fragmentDataMap = section3Data?.fragments?.map(section => {
        let returnData = {}
        section.forEach(fragmentData => {
            switch (fragmentData.key) {
                case 'Image':
                    returnData['image'] = fragmentData.value
                    break
                default:
                    break
            }
        })
        return {
            image: returnData.image
        }
    })

    return fragmentDataMap
}

const parseCollectionData = collectionData => {
    let fragmentDataMap = collectionData?.fragments?.map(section => {
        let returnData = {}
        section.forEach(fragmentData => {
            switch (fragmentData.key) {
                case 'Image':
                    returnData['img'] = fragmentData.value
                    break
                case 'Overlay':
                    returnData['overlay'] = fragmentData.value
                    break
                case 'Title':
                    returnData['title'] = fragmentData.value
                    break
                case 'Text':
                    returnData['text'] = fragmentData.value
                    break
                case 'ItemColor':
                    returnData['color'] = fragmentData.value
                    break
                case 'ProductLink':
                    returnData['productLink'] = fragmentData.value
                    break
                case 'HighlightText':
                    returnData['highlightText'] = fragmentData.value
                    break
                default:
                    break
            }
        })
        return {
            image: returnData.img,
            hoverImage: returnData.overlay,
            title: returnData.title,
            text: returnData.text,
            text2: returnData.highlightText,
            ctaText: returnData.color,
            ctaLink: returnData.productLink,
        }
    })

    return fragmentDataMap
}

const parseCarouselSlides = slides => {
    return slides?.map(s => {
        return {
            before: s.image?.localFile?.publicURL,
            after: s.mobileImage?.localFile?.publicURL,
        }
    })
}


const SBLanding = ({ intl, page }) => {
    const {
        banner, // hero banner
        title, // page title
        text: subTitleText, // subheading text
        section1Title: newTitle, // new cream lightener block title bold
        section1Subtitle: newProductTitle, // new cream lightener block subtitle normal
        sectionData, // new cream lightener block desktop image and hover
        section1Data, // new cream lightener block mobile image
        moreCollectionTitle: beforeAfterHeaderTitle, // before after header block text
        section3Data, // before after image array
        collectionTitleColor: simplyBlondeProductsTitle, // other products title
        collectionData, // the other SB products
        moreCollectionData, // extra two product groups
        carouselTitle: beforeAfterBottomTitle, // before and after bottom section title
        carouselSlides, // before and after bottom section imagery
        educationVideoTitle, // video title
        educationVideoImage, // image for video placeholder
        educationVideos, // the video file to play
        sustainabilityTitle: videoCtaText, // video cta text
        section2Text: videoYoutubeUrl, // youtube url to video
        collection1Benefits: collectionLearnMore
    } = page


    const newCreamLightenerBlockDesktopData = parseSectionData(sectionData)
    const newCreamLightenerBlockMobileData = parseSection1Data(section1Data)
    const topBeforeAfterBlockImages = parseSection3Fragments(section3Data)
    const simplyBlondeProducts = parseCollectionData(collectionData)
    const moreSimplyBlondeProducts = parseCollectionData(moreCollectionData)
    const beforeAfterImagery = parseCarouselSlides(carouselSlides)


    return (
        <Fragment>
            {banner && (
                <Spacing removeSpaceTop>
                    <HeroSlider slides={banner} />
                </Spacing>
            )}

            {title && (
                <Spacing removeSpaceBottom>
                    <MobileShadow>
                        <StPageTitle>
                            <span
                                style={{
                                    fontSize: "36px",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    lineHeight: "115%",
                                    letterSpacing: "2px",
                                    textTransform: "uppercase",
                                    color: "#121212",
                                }}
                            >
                                {title}
                            </span>
                            <br />
                            <span style={{
                                fontSize: "24px",
                                lineHeight: "115%",
                                letterSpacing: "2px",
                                textTransform: "unset",
                                color: "#121212",
                            }}>
                                {documentToReactComponents(JSON.parse(subTitleText.raw || "{}"))}
                            </span>
                        </StPageTitle>
                    </MobileShadow>
                </Spacing>
            )}

            {newCreamLightenerBlockDesktopData && (
                <Spacing>
                    <Container>
                        <StPageTitle style={{ marginBottom: 12 }}>
                            <span
                                style={{
                                    fontWeight: "700",
                                    fontSize: "36px",
                                    fontStyle: "normal",
                                    color: "#121212",
                                    lineHeight: "115%",
                                    letterSpacing: "2px",
                                    textTransform: "uppercase",
                                }}
                            >
                                {newTitle}
                            </span>{" "}
                            <span
                                style={{
                                    fontWeight: "normal",
                                    fontSize: "36px",
                                    fontWeight: "500",
                                    lineHeight: "115%",
                                    letterSpacing: "2px",
                                    textTransform: "uppercase",
                                    color: "#121212",
                                }}
                            >
                                {newProductTitle}
                            </span>
                        </StPageTitle>
                        <ItemImageWrapper>
                            <DesktopImage src={newCreamLightenerBlockDesktopData[0].image} />
                            <Image src={newCreamLightenerBlockDesktopData[1].image} />
                            <MobileImage src={newCreamLightenerBlockMobileData[0].image} />
                        </ItemImageWrapper>
                        <ImageOverlay>
                            {newCreamLightenerBlockDesktopData[0].text}
                            <OverlayLink hoverColor={'#fff'}>
                                <CustomLink target={"_blank"} style={{ 'letterSpacing': '0.659px' }} href={newCreamLightenerBlockDesktopData[0].url}>
                                    {newCreamLightenerBlockDesktopData[0].link}
                                </CustomLink>
                            </OverlayLink>
                        </ImageOverlay>
                    </Container>
                </Spacing>
            )}

            {beforeAfterHeaderTitle && topBeforeAfterBlockImages && (
                <Spacing>
                    <BeforeAfterRow>
                        <BeforeAfterTitleBlock>{beforeAfterHeaderTitle}</BeforeAfterTitleBlock>
                        {topBeforeAfterBlockImages.map(i => {
                            return <BeforeAfterRowImage>
                                <img src={i.image} />
                            </BeforeAfterRowImage>
                        })}
                    </BeforeAfterRow>
                </Spacing>
            )}

            {educationVideoTitle && educationVideoImage && educationVideos && (
                <Spacing>
                    <StPageTitle style={{ marginBottom: 16 }}>
                        <h1
                            style={{
                                fontSize: '25px',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: '115%',
                                letterSpacing: '2px',
                                textTransform: "uppercase",
                                color: "#121212",
                            }}
                        >
                            {educationVideoTitle}
                        </h1>
                    </StPageTitle>
                    <div style={{ justifyContent: 'center', display: 'flex' }}>
                        <a href={`/video/${educationVideos[0].slug}`} style={{ display: 'flex' }}>
                            <ButtonPlay removeCircle>
                                <VideoImage>
                                    <img src={educationVideoImage?.localFile?.publicURL} />
                                </VideoImage>
                            </ButtonPlay>
                        </a>
                    </div>
                    {videoCtaText && videoYoutubeUrl && (<Fragment>
                        <StLinkMore rounded invertTextColor center topMargin bottomMargin morePadding hoverColor={purple} onetwoColor responsiveFont>
                            <CustomLink target={"_blank"} style={{ 'letterSpacing': '0.659px' }} href={`/video/${educationVideos[0].slug}`}>
                                {videoCtaText}
                            </CustomLink>
                        </StLinkMore>
                    </Fragment>

                    )}
                </Spacing>
            )}

            {simplyBlondeProducts && simplyBlondeProductsTitle && (
                <Spacing>
                    <StPageTitle>
                        <h1
                            style={{
                                fontSize: '25px',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: '115%',
                                letterSpacing: '2px',
                                textTransform: "uppercase",
                                color: "#121212",
                            }}
                        >
                            {simplyBlondeProductsTitle}
                        </h1>
                    </StPageTitle>
                    {simplyBlondeProducts?.map((p, index) => {
                        return <DesktopProductRow key={p.title}>
                            {index % 2 === 0 && <ProductImageContainer>
                                <ItemImageWrapper>
                                    <DesktopImage src={p.image} />
                                    <Image src={p.hoverImage} />
                                </ItemImageWrapper>
                            </ProductImageContainer>}

                            <ProductInfoContainer>
                                <ProductInfoTitle>{p.title}</ProductInfoTitle>
                                <ProductInfoText>{p.text}</ProductInfoText>
                                {p.text2 && <ProductInfoText>{p.text2}</ProductInfoText>}
                                <StLinkMore rounded invertTextColor center topMargin bottomMargin morePadding hoverColor={purple} onetwoColor responsiveFont>
                                    <CustomLink target={"_blank"} style={{ 'letterSpacing': '0.659px' }} href={`${p.ctaLink}`}>
                                        {p.ctaText}
                                    </CustomLink>
                                </StLinkMore>
                            </ProductInfoContainer>

                            {index % 2 === 1 && <ProductImageContainer>
                                <ItemImageWrapper>
                                    <DesktopImage src={p.image} />
                                    <Image src={p.hoverImage} />
                                </ItemImageWrapper>
                            </ProductImageContainer>}

                        </DesktopProductRow>
                    })}
                    {simplyBlondeProducts && (
                        <ProductCarousel products={simplyBlondeProducts} />
                    )}

                </Spacing>
            )}

            {moreSimplyBlondeProducts && <Spacing>
                <DesktopAlternativeProductContainer>
                    {moreSimplyBlondeProducts.map(p => {
                        return <DesktopAlternativeProduct>
                            <ItemImageWrapper>
                                <DesktopImage src={p.image} />
                                <Image src={p.hoverImage} />
                            </ItemImageWrapper>
                            <ProductInfoTitle wide>{p.title}</ProductInfoTitle>
                            <ProductInfoText wide>{p.text}</ProductInfoText>
                            {p.text2 && <ProductInfoText wide>{p.text2}</ProductInfoText>}
                            <StLinkMore rounded invertTextColor center topMargin bottomMargin morePadding hoverColor={purple} onetwoColor responsiveFont>
                                <CustomLink style={{ 'letterSpacing': '0.659px' }} target={"_blank"} href={`${p.ctaLink}`}>
                                    {p.ctaText}
                                </CustomLink>
                            </StLinkMore>
                        </DesktopAlternativeProduct>
                    })}
                </DesktopAlternativeProductContainer>
            </Spacing>}

            {moreSimplyBlondeProducts && (
                <Spacing>
                    <ProductCarousel shorter={true} products={moreSimplyBlondeProducts} />
                </Spacing>
            )}

            {beforeAfterBottomTitle && beforeAfterImagery && (
                <Spacing removeSpaceTop>
                    <BeforeAfterRow numberInRow={4}>
                        <BeforeAfterTitleBlock numberInRow={4}>{beforeAfterBottomTitle}</BeforeAfterTitleBlock>
                        {beforeAfterImagery.map((i, index) => {
                            return AutoScrollBeforeAfterImage([i.before, i.after], index)
                        })}
                    </BeforeAfterRow>
                </Spacing>
            )}

            {collectionLearnMore && collectionLearnMore.length > 0 && (
                <Spacing>
                    <StPageTitle style={{ marginBottom: 12, marginLeft: 24, marginRight: 24 }}>
                        <h1
                            style={{
                                fontSize: '25px',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: '115%',
                                letterSpacing: '2px',
                                textTransform: "uppercase",
                                color: "#121212",
                            }}
                        >
                            {collectionLearnMore[0].slideText}
                        </h1>
                    </StPageTitle>
                    <ItemImageWrapper style={{ maxWidth: '900px' }} >
                        <Image src={collectionLearnMore[0].image?.localFile?.publicURL} />
                    </ItemImageWrapper>
                    <StLinkMore rounded invertTextColor center topMargin bottomMargin morePadding hoverColor={purple} onetwoColor responsiveFont>
                        <CustomLink style={{ 'letterSpacing': '0.659px' }} target={"_blank"} href={`${collectionLearnMore[0].slideLink}`}>
                            {collectionLearnMore[0].slideLinkText}
                        </CustomLink>
                    </StLinkMore>
                </Spacing>
            )}

        </Fragment>
    )
}

export default injectIntl(SBLanding)
