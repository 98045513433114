import React, { useState, useEffect, Fragment } from "react"
import styled, { css } from "styled-components"
import settings from "../../../kenra-storybook/global/settings"

export const BeforeAfterRowImage = styled.div`
    margin: 1vw;
    align-self: center;
    @media (min-width: ${settings.bp.medium.view}) {
        width: 28%;
    }
    ${props =>
        props.numberInRow === 4 &&
        css`
            @media (min-width: ${settings.bp.medium.view}) {
                width: 25%;
            }
        `};

        ${props =>
        props.smallerMobile &&
        css`
                @media (max-width: ${settings.bp.medium.view}) {
                    margin: 1vw;
                    width: 43vw;
                }
            `};
`

export const AutoScrollBeforeAfterImage = (images, index) => {
    const [imageIndex, setImageIndex] = useState(0)
    useEffect(() => {
        setTimeout(() => {
            setImageIndex(imageIndex === 0 ? 1 : 0)
        }, 1500)
    }, [imageIndex])
    return <BeforeAfterRowImage key={`ba-image-${index}`} smallerMobile={[0, 1].includes(index)} ><img src={images[imageIndex]} /></BeforeAfterRowImage>
}